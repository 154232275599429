.spinner-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.spinner {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  &--xsm {
    height: 5vh;
  }

  &--sm {
    height: 20vh;
  }
  &--md {
    height: 40vh;
  }

  &--lg {
    height: 100vh;
  }
}
