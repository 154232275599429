// _padding.scss

// Base Padding Variables
$padding-small: 0.5rem;    // 8px
$padding-medium: 1rem;     // 16px
$padding-large: 1.5rem;    // 24px
$padding-xlarge: 2rem;     // 32px

// Padding Mixins
@mixin padding-small {
  padding: $padding-small;
}

@mixin padding-medium {
  padding: $padding-medium;
}

@mixin padding-large {
  padding: $padding-large;
}

@mixin padding-xlarge {
  padding: $padding-xlarge;
}

// Utility Classes for Padding
.pt-small {
  padding-top: $padding-small;
}

.pt-medium {
  padding-top: $padding-medium;
}

.pt-large {
  padding-top: $padding-large;
}

.pt-xlarge {
  padding-top: $padding-xlarge;
}

.pb-small {
  padding-bottom: $padding-small;
}

.pb-medium {
  padding-bottom: $padding-medium;
}

.pb-large {
  padding-bottom: $padding-large;
}

.pb-xlarge {
  padding-bottom: $padding-xlarge;
}

.pl-small {
  padding-left: $padding-small;
}

.pl-medium {
  padding-left: $padding-medium;
}

.pl-large {
  padding-left: $padding-large;
}

.pl-xlarge {
  padding-left: $padding-xlarge;
}

.pr-small {
  padding-right: $padding-small;
}

.pr-medium {
  padding-right: $padding-medium;
}

.pr-large {
  padding-right: $padding-large;
}

.pr-xlarge {
  padding-right: $padding-xlarge;
}

@media (max-width: 576px) {
  body {
    .no-padding {
      padding: 0;
    }
  }
}